<template>
  <div>
    <b-dropdown
      size="lg"
      variant="link"
      dropleft
      toggle-class="text-decoration-none"
      no-caret
      style="top: 8px;"
    >
      <template #button-content>
        <b-avatar style="margin-right: 10px; color: #fe6637"  :src="'https://api.neearby.com/api/users/' +user.login +'/mini'"> </b-avatar> {{user.name}}      </template>
      
      <b-dropdown-item v-if="user.login" :href="'https://' + user.login + '.neearby.com/page'"><b-icon icon="person-circle" style="color: #fe6637; margin-right: 7px;"></b-icon>Моя страница</b-dropdown-item>
      <b-dropdown-item :href="'https://neearby.com'+'/mysubs'" ><b-icon icon="github" style="color: #fe6637; margin-right: 7px;"></b-icon>Мои подписки</b-dropdown-item>
      <b-dropdown-item :href="'https://neearby.com'+'/Settings'"><b-icon icon="gear" style="color: #fe6637; margin-right: 7px;"></b-icon>Мои настройки</b-dropdown-item>
      <b-dropdown-item v-if="user.login" :href="'https://' + user.login + '.neearby.com'+'/statisticks'"><b-icon icon="table" style="color: #fe6637; margin-right: 7px;"></b-icon>Моя статистика</b-dropdown-item>
      <b-dropdown-item :href="'https://neearby.com' + '/chats'"> <b-icon icon="chat-dots-fill" style="color: #fe6637; margin-right: 7px;"></b-icon>Мои сообщения</b-dropdown-item>

      <b-dropdown-divider></b-dropdown-divider>
      <b-dropdown-item v-if="user.login" :href="'https://'+ user.login + '.neearby.com' + '/redirect'" > <div class="notification_addition"><span class="notification_span_redirect"><-></span>Редирект центр</div></b-dropdown-item>
      <b-dropdown-divider></b-dropdown-divider>
      <b-dropdown-item v-if="user.login" :href="'https://' + user.login + '.neearby.com'+'/Money'"><b-icon icon="wallet2" style="color: #fe6637; margin-right: 7px;"></b-icon>Вывод средств  </b-dropdown-item>
      <b-dropdown-item :href="'https://neearby.com' + '/notifications'" > <div class="notification_addition"><span class="notification_span">{{notify}}</span>Уведомления</div></b-dropdown-item>
      <b-dropdown-divider></b-dropdown-divider>
      <b-dropdown-item @click="logout()"><b-icon icon="box-arrow-left" style="color: #fe6637; margin-right: 7px;"></b-icon>Выйти</b-dropdown-item>
    </b-dropdown>
  </div>
</template>
<script>
import {getCookie, setCookie, deleteCookie} from "../utils.js"
import Api from "../api";
export default {
  name: "registrated",
  data(){
    return {
      notify: 0
    }
  },
  methods: {
    logout() {
      deleteCookie('token')
      localStorage.removeItem('token')
      console.log(document.cookie)
      window.location.reload();
    },
  },
  async mounted() {
    this.notify = await Api.users.getCountNotify(localStorage.getItem('token'))
  },
  props: {
    user: Object,
  },
};
</script>
<style lang="scss">
.notification_span_redirect {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  color: #fe6637;
}
.notification_span {
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background: #fe6637;
  margin-right: 8px;
  color: #ffffff;
  font-size: 70%;
}
.notification_addition {
  display: flex;
  align-items: center;
}
</style>