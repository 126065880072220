import axios from "axios";

axios.defaults.withCredentials = true;
axios.defaults.baseURL = "https://api.neearby.com/";
// axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'
//axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'

// axios.defaults.headers.common['Authorization'] = Bearer ${TokenApi};

import { getCookie, setCookie, deleteCookie } from "../utils";

axios.interceptors.response.use(
  function(response) {
    return response.data;
  },
  (error) => {
    return error.response.data;
  }
);

export default {
  users: {
    async registration(user) {
      return await axios.post("api/users/registration", user);
    },
    async authorization(user) {
      return await axios.post("api/users/authorization", user);
    },
    async JWT(token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.post("api/users", {}, config);
    },
    async updateproff(data, token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.put("api/users", data, config);
    },

    async becomeauthored(login, token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.post("api/users/becomeauthor", login, config);
    },
    async getauthor(id) {
      return await axios.get(`api/users/${id}`);
    },
    async updateoblojka(data, token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.post("api/users/update/oblojka", data, config);
    },
    async updateProfile(data, token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.post("api/users/update/photo", data, config);
    },
    async addsubsription(data, token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.post("api/subscriptions", data, config);
    },
    async updateSub(data, id, token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.put(`api/subscriptions/${id}`, data, config);
    },
    async chtoto(id, token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.get(`api/subscriptions/${id}`, {}, config);
    },
    async addlike(id, token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.post(`api/users/subscribe/${id}`, {}, config);
    },
    async ascsub(idauthor, iduser) {
      return await axios.get(`api/users/subscribe/${idauthor}/${iduser}`);
    },
    async unlike(idauthor, token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.post(`api/users/unsubscribe/${idauthor}`, {}, config);
    },
    async publication(file, token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.post(`api/publication/storage/upload`, file, config);
    },
    async infozygane(data, token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.post(`api/publication`, data, config);
    },
    async vrotebal(id, data, offSet) {
      return await axios.post(`api/publication/${id}/${offSet}`, data);
    },
    async changepost(data, token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.post(`api/publication`, data, config);
    },
    async getcurpost(id, token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.get(`api/publication/${id}`, config);
    },
    async updatepost(data, token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.put(`api/publication`, data, config);
    },
    async updatepost(data, token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.put(`api/publication`, data, config);
    },
    async deletepost(id, token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.delete(`api/publication/${id}`, config);
    },
    async aboutauthorget(data, token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.put(`api/users/desc`, data, config);
    },
    async getgetauthor() {
      return await axios.get("api/users/cataloge/get/author");
    },
    async getallmysubs(token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.post("/api/users/subsc", {}, config);
    },
    async deletesubsc(id, token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.delete(`api/subscriptions/${id}`, config);
    },
    async like(id, token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.put(`api/publication/like/${id}`, {}, config);
    },
    async uunlike(id, token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.put(`api/publication/unlike/${id}`, {}, config);
    },
    async newtovar(goods, token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.post(`api/goods`, goods, config);
    },
    async deletetovar(id, token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.delete(`api/goods/${id}`, config);
    },
    async gettovar() {
      return await axios.get(`api/goods`);
    },
    async getauthortovar(id) {
      return await axios.get(`api/goods/${id}`);
    },
    async postmessage(id, token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.post(`api/users/chats/${id}`, {}, config);
    },
    async buypost(id, token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.post(`api/buying/post/${id}`, {}, config);
    },
    async buysubcur(id, token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.post(`api/buying/subs/${id}`, {}, config);
    },
    async buycurtovar(id, token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.post(`api/buying/tovar/${id}`, { refKey: getCookie('refKey') }, config);
    },
    async buycurtovar(id, token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.post(`api/buying/tovar/${id}`, {}, config);
    },
    async rightcomment(id, token, data) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.post(`api/publication/comment/${id}`, data, config);
    },
    async gettoppod(day) {
      return await axios.get(`api/subscriptions/top/${day}`);
    },
    async gettoptovar(day) {
      return await axios.get(`api/goods/top/${day}`);
    },
    async getcurtov(id) {
      return await axios.get(`api/goods/byId/${id}`);
    },
    async getsubbyid(id) {
      return await axios.get(`api/subscriptions/get/id/${id}`);
    },
    async getallacc() {
      return await axios.get(`api/goods/getall/acia`);
    },
    async getallusers(token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.get(`admin/users`, config);
    },
    async getcatalog() {
      return await axios.get(`api/cataloge`);
    },
    async buybuy(data, token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.post("api/buying/registr", data, config);
    },
    async buyvip(data, token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.post("api/buying/vip", data, config);
    },
    async getacci(data, id, token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.post(`api/goods/create/acia/${id}`, data, config);
    },
    async banuser(id, token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.post(`admin/ban/${id}`, {}, config);
    },
    async sendmesssage(data, token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.post(`admin/send/chats`, data, config);
    },
    async deletecomm(id, token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.delete(`api/publication/comment/${id}`, config);
    },
    async changecom(token, id, data) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.put(`api/publication/comment/${id}`, data, config);
    },
    async sendob(data, token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.post(`api/cataloge`, data, config);
    },
    async statick(token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.get(`api/users/4/statics`, config);
    },
    async changetov(token, id, data) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.put(`api/goods/byId/${id}`, data, config);
    },
    async oplataunreg(data) {
      return await axios.post(`api/buying/registr`, data);
    },
    async forg(data) {
      return await axios.post(`/api/users/4/password`, data);
    },
    async unsub(token, id) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.post(`api/users/4/deletePayReucrent/${id}`, {}, config);
    },
    async getFree(id) {
      return await axios.get(`api/users/${id}/getFreeSub`);
    },
    async sendinsult(data, token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.post(`admin/send/insult`, data, config);
    },
    async getcomplains(token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.get(`admin/insult`, config);
    },
    async getcomplainobj(token, type, id) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.get(`admin/get/${type}/${id}`, config);
    },
    async deleteobject(id, token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.get(`admin/insult/delete/${id}`, config);
    },
    async getbans(token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.get(`admin/users/ban`, config);
    },
    async unban(token, id) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.post(`admin/unban/${id}`, {}, config);
    },
    async creategroup(token, data) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.post(`/api/mailer/group`, data, config);
    },
    async getgroup(token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.get(`api/mailer/group`, config);
    },
    async getauto(token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.get(`api/mailer/sender`, config);
    },
    async sendsendler(token, data) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.post(`/api/mailer/sender`, data, config);
    },
    async dest(id) {
      return await axios.delete(`mailer/destroy/${id}`);
    },
    async conf(id) {
      return await axios.get(`mailer/confirm/${id}`);
    },
    async getCard(token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.get(`/api/bank-card`, config);
    },
    async addCard(token, data) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.post(`/api/bank-card`, data, config);
    },
    async path(token, id) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.post(`/api/bank-card/${id}`, {}, config);
    },
    async deleteCard(token, id) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.delete(`/api/bank-card/${id}`, config);
    },
    async dobCard(token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.get(`/api/buying/payout`, config);
    },

    async updatemini(data, token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.post("api/users/update/mini", data, config);
    },
    async getBannedWord(token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.get(`admin/get/nsfw`, config);
    },
    async getCountNotify(token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.get(`notify/count`, config);
    },
    async ReadNotify(token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.get(`notify/read`, config);
    },
    async getNotify(token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.get(`notify/all`, config);
    },
    async deleteWord(token, id) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.delete(`admin/nsfw/${id}`, config);
    },
    async addbanword(token, data) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.post(`/admin/nsfw/add`, data, config);
    },
    async getnewstats(token, type) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.get(`api/users/0/statistic/${type}`, config);
    },
    async createCategories(token, data) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.post(`/api/redirect/category`, data, config);
    },
    async getCategoriesRedirect(token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.get(`api/redirect/category/`, config);
    },
    async createRedirect(token, data) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.post(`/api/redirect`, data, config);
    },
    async goToRedirect(data) {
      return await axios.post(`/api/redirect/controller`, data);
    },
    async redirectGetAllCategory(token, type) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.get(`api/redirect/allcategory/${type}`, config);
    },
    async getCategoryById(token, id) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.get(`api/redirect/category/${id}`, config);
    },
    async refactorCategory(data, token, id) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.put(`api/redirect/category/${id}`, data, config);
    },
    async deleteCategoryId(token, id) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.delete(`api/redirect/category/${id}`, config);
    },
    async getRedirect(token, id) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.get(`api/redirect/redirect/${id}`, config);
    },
    async putRedirect(token, data, id) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.put(`api/redirect/redirect/${id}`, data, config);
    },
    async getAnalytics(token, id) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.get(`api/redirect/redirect/${id}/statistic`, config);
    },
    async deleteRedirectId(token, id) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.delete(`api/redirect/redirect/${id}`, config);
    },
    async procentGet(token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.get(`api/buying/procent`, config);
    },
    async procentPost(token, data) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.post(`/api/buying/procent`, data, config);
    },
    async linkCard(token, data) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.post(`/api/card/linkcrad`, data, config);
    },
    async withdrawMoney(token, data) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await axios.post(`/api/bank-card/withdraw`, data, config);
    }
  }

};
